import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Truevined Music Website",
  "featuredImage": "images/truevined.png",
  "coverAlt": "Truevined Music Website",
  "description": "Front end development, Design, UX + Graphic design for indie artist Truevined",
  "date": "2018-03-14",
  "dateModified": "2018-03-14",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["programming", "stuff", "other"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I rebranded and redesigned Truevined from the ground up to be cleaner looking, spaced out  and less cluttered. This was the result.`}</p>
    <p>{`*`}{`*`}{` Skills Used`}</p>
    <p>{`*`}{`*`}{` Software`}</p>
    <p>{`*`}{`*`}{` Cool Features`}</p>
    <p>{`Responsive mobile first design`}</p>
    <p>{`Graphic Design`}</p>
    <p>{`Logo Design`}</p>
    <p>{`HTML 5`}</p>
    <p>{`CSS 3`}</p>
    <p>{`JQuery`}</p>
    <p>{`Javascript`}</p>
    <p>{`SEO`}</p>
    <p>{`Responsive web development Adobe Photoshop`}</p>
    <p>{`Blogger`}</p>
    <p>{`Notepad++`}</p>
    <p>{`Google`}</p>
    <p>{`Integrated e-commerce platform`}</p>
    <p>{`JavaScript Music Player`}</p>
    <p>{`Fully responsive`}</p>
    <h2 {...{
      "id": "see-it-live-wwwtruevinedcom",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-it-live-wwwtruevinedcom",
        "aria-label": "see it live wwwtruevinedcom permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See it live (`}<a parentName="h2" {...{
        "href": "http://www.truevined.com/"
      }}>{`www.truevined.com`}</a>{`)`}</h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1381px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b0647792c38c00b5161155629554a9ce/4259f/truevined.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.94444444444445%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC+0lEQVQoz2VRa0jTcRS99jBtNs0xH6Vzay5zatPyMZdOp6w91Mw2TAt1SVFppUFamuXft6jl5iMFtexJRZgZlsulZBQ9qA99j4j6ENV8VK7a/v/fjfWC6MDhHi7cyzkccHV1dYFf8PJc5tHi7ck2sVhL293ZXkY3D5YRXJcYAcDIW8E7uaNwV09VLbWhydQOR2tr/tz9C3d31oLfUhQoisWQdWqMi0/DzeJI3BKjxGpdPlbtr8D6ng6sq6nGQm1mVZVWDzp5ymIul+viJIfDcWGz2eAksL25fx/yZNl2gSyfFks2OhI2FToOpBc57u6pcExeG3W8oN9/G7lvRk1sfKmQvfw/Y5zotbBMEgogTkoB36Vuzp2Pb0QSExCVgX6rYkiYfg/ZVHCEdEeriaWyjgxN3KGp3g70FfAbAcAbAAQAwAOAIOf0V8Qv8k6IBuAFiwIEK1YeCREGt0XEpTKro1IwcI2UxCUqiVauxjJZChmMzSA9hhIyMXUPz9dT89eGhj5WUzXWsVs3ref6T1lP9/XP8j08xWIff4BA/iqFPDQKDylzsGtfGZFJEzBZoSUiiZwR8YQYGcAnMjcOXabbziAisc5acWb+C756+wbnbDacttnQfPEKegFIfJw5/Vnuif7rU7E4+6DdpMhiqGPH6LsWC7aeMGJDY7O9f+A06Rs4gyc7O3GetpMPnz+RL/bvzNxXG/NhboaxI808fv4MFQqFRKVSAQiCgpJaTJ14e3wKh7p6kTpO4dXh4dfDN0bHx8wWvDf1YBoR1eq09Mup6jTU6XPt0uRkOreggNbnGWhltoGOjJMiAIQKhEIAoTA4Nk+n+9jW2vKOam5+Gx4RMZMgkz7q6OrJun59pPL8hUt7jz58uVCZKO/OycjCLdpMDODzcVtuDu7cXYRJW4tRJA7HAoNB9bPutWFhiwDAz5ne2fRv7We2TMKTp89hYnIKbo6MepnajYrKygpt+eFyTUtTs6a0pERz8MB+DVVeomlqqE87e2YwHBFdfgBN4UREnuzk8AAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Truevined Music Website",
              "title": "Truevined Music Website",
              "src": "/static/b0647792c38c00b5161155629554a9ce/4259f/truevined.png",
              "srcSet": ["/static/b0647792c38c00b5161155629554a9ce/53918/truevined.png 360w", "/static/b0647792c38c00b5161155629554a9ce/242a6/truevined.png 720w", "/static/b0647792c38c00b5161155629554a9ce/4259f/truevined.png 1381w"],
              "sizes": "(max-width: 1381px) 100vw, 1381px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Truevined Music Website`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      